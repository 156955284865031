import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  faLinkedin,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function App() {
  return (
    <div className="App"
      style={{
        marginTop: '55px'
      }}>
        <p>Hi! I'm</p>
        <h1 style={{marginBottom: 55}}>James Milloff</h1>
        <p style={{marginBottom: 55}}>If you're looking for my Software Engineering resume:<br/><a href="https://www.linkedin.com/in/james-milloff/">Click here to go to my LinkedIn.</a></p>
        <p style={{marginBottom: 55}}>If you're looking for my hobby photography:<br/><a href="https://www.instagram.com/goes.by.james/">Click here to go to my Instagram.</a></p>
        <p>Or you can click the buttons below<span title="Shrug Emote"> ¯\_(ツ)_/¯</span></p>
        <div style={{fontSize: 'xx-large'}}>
          <a href="https://www.linkedin.com/in/james-milloff/"><FontAwesomeIcon style={{marginRight: 25}} icon={faLinkedin}/></a>
          <a href="https://www.instagram.com/goes.by.james/"><FontAwesomeIcon icon={faInstagram}/></a>
        </div>
    </div>
  );
}

export default App;
